<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
            <p class="is-size-5 has-text-weight-bold mb-sm">Duplicate Project</p>
            <p class="is-size-7">
              Duplicating <strong>{{ project.name }}</strong> will create a new project with the same budgets and
              the same team, but you can customize the new project name and dates below.
            </p>
          </b-message>
        <!-- Name -->
        <b-field
          label="New Project Name"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''">
          <b-input
            size="is-medium"
            type="text"
            v-model="name"
            expanded
          ></b-input>
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field
              label="New Start Date"
              :type="('start_date' in errors) ? 'is-danger' : ''"
              :message="('start_date' in errors) ? errors['start_date'][0] : ''">
              <b-input
                size="is-medium"
                type="date"
                v-model="startDate"
                :min="minStartDate"
                :max="maxStartDate"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="New End Date"
              :type="('end_date' in errors) ? 'is-danger' : ''"
              :message="('end_date' in errors) ? errors['end_date'][0] : ''">
              <b-input
                size="is-medium"
                type="date"
                v-model="endDate"
                :min="minEndDate"
                :max="maxEndDate"
              ></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between has-background-white">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Duplicate Project</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.name && this.startDate && this.endDate)
    },
    minStartDate () {
      return this.$dates().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
    },
    maxStartDate () {
      return (this.endDate) ? this.endDate : null
    },
    minEndDate () {
      return (this.startDate) ? this.startDate : null
    },
    maxEndDate () {
      return this.$dates().add(13, 'month').startOf('month').format('YYYY-MM-DD')
    },
    json () {
      return {
        name: this.name,
        start_date: this.startDate,
        end_date: this.endDate
      }
    }
  },
  data () {
    return {
      name: null,
      startDate: null,
      endDate: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/duplicate'
      this.$http.put(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project duplicated!' })
        this.$emit('project:duplicated', { id: response.data.id })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
