<template>
  <div>
    <form v-if="!loading" @submit.prevent="save">
      <!-- Section 1: Expense Form -->
      <div class="has-background-white has-shadow pa-lg mb-xxs">
        <!-- Header -->
        <div class="border-b pb-md mb-lg">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold is-size-5 has-text-primary">
                <span v-if="expense.id">Expense #{{ expense.id }}</span>
                <span v-else>Submit New Expense</span>
              </p>
              <p>
                <StatusPresenter :expense="(expense) ? expense : {}"></StatusPresenter>
                <b-tag v-if="expense.exported_at" class="ml-sm">Exported</b-tag>
              </p>
            </div>
            <div class="column is-narrow">
              <router-link :to="{ name: 'network.project.expenses', params: { code: context.code, id: project.id }}">&lt; Back to Expenses</router-link>
            </div>
            <div class="column is-narrow">
              <div class="field is-grouped">
                <p class="control">
                  <b-button
                    v-if="expense.approved_at === null"
                    type="is-primary"
                    :disabled="!expense.id"
                    @click="showApproveExpenseModal = true"
                  >Approve</b-button>
                  <b-button
                    v-if="expense.approved_at"
                    type="is-primary"
                    :disabled="!expense.id || expense.exported_at"
                    @click="showUnapproveExpenseModal = true"
                  >Remove Approval</b-button>
                </p>
                <p class="control">
                  <b-dropdown position="is-bottom-left">
                    <template #trigger="{ active }">
                      <b-button
                        type="is-primary"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                        outlined
                      >More</b-button>
                    </template>
                    <b-dropdown-item :disabled="!expense.id" @click="showDetailsModal = true">View Advanced Details</b-dropdown-item>
                    <b-dropdown-item :disabled="!exportable" @click="showExportExpenseModal = true">Export Expense</b-dropdown-item>
                    <hr class="dropdown-divider">
                    <b-dropdown-item :disabled="!expense.id || readonly" @click="showArchiveExpenseModal = true">Archive Expense</b-dropdown-item>
                  </b-dropdown>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Form -->
        <div class="mb-lg">
          <b-message v-if="'general' in errors" type="is-danger" class="is-size-7">
            {{ errors.general[0] }}
          </b-message>
          <b-message v-else-if="readonly" type="is-info" class="is-size-7">
            This expense has been approved {{(expense.exported_at) ? '(and exported)' : '' }} and can no longer be edited.
          </b-message>
          <div class="columns">
            <div class="column is-half">
              <b-field
                label="Vendor"
                :type="('vendor' in errors) ? 'is-danger' : ''"
                :message="('vendor' in errors) ? errors['vendor'][0] : ''">
                <b-input v-model="expense.vendor" size="is-medium" :readonly="readonly" :disabled="readonly" />
              </b-field>
              <b-field
                label="Expensed By (optional)"
                :type="('expensed_by' in errors) ? 'is-danger' : ''"
                :message="('expensed_by' in errors) ? errors['expensed_by'][0] : ''">
                <b-select
                  v-model="expense.partner_role_id"
                  expanded size="is-medium"
                  placeholder="- Select Contributor -"
                  :readonly="readonly"
                  :disabled="readonly"
                  ><option v-for="(name, id) in contributors" :key="id" :value="id">{{ name }}</option>
                </b-select>
              </b-field>
              <b-field
                label="Payment Method"
                :type="('payment_method_used' in errors) ? 'is-danger' : ''"
                :message="('payment_method_used' in errors) ? errors['payment_method_used'][0] : ''">
                <b-select
                  v-model="expense.payment_method_used"
                  expanded size="is-medium"
                  placeholder="- Select Payment Method -"
                  :readonly="readonly"
                  :disabled="readonly">
                    <option value="cash">Cash</option>
                    <option value="company_card">Company Card</option>
                    <option value="personal_card">Personal Card</option>
                </b-select>
              </b-field>
              <b-field
                label="Description (optional)"
                :type="('description' in errors) ? 'is-danger' : ''"
                :message="('description' in errors) ? errors['description'][0] : ''">
                <b-input v-model="expense.description" type="textarea" :readonly="readonly" :disabled="readonly" size="is-small" />
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Terms"
                :type="('sales_term_id' in errors) ? 'is-danger' : ''"
                :message="('sales_term_id' in errors) ? errors['sales_term_id'][0] : ''">
                <b-select
                  v-model="expense.sales_term_id"
                  expanded size="is-medium"
                  placeholder="- Select Terms -"
                  :readonly="readonly"
                  :disabled="readonly"
                  ><option v-for="term in salesTerms" :key="term.id" :value="term.id">{{ term.name }}</option>
                </b-select>
              </b-field>
              <b-field
                label="Expense Date"
                :type="('expense_date' in errors) ? 'is-danger' : ''"
                :message="('expense_date' in errors) ? errors['expense_date'][0] : ''"
                expanded>
                <b-input v-model="expense.expense_date" type="date" size="is-medium" :readonly="readonly" :disabled="readonly" />
              </b-field>
              <b-field
                label="Due Date"
                :type="('due_date' in errors) ? 'is-danger' : ''"
                :message="('due_date' in errors) ? errors['due_date'][0] : ''"
                expanded>
                <b-input v-model="expense.due_date" type="date" size="is-medium" :readonly="readonly" :disabled="readonly" />
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <!-- Section 2: Expense Lines -->
      <div class="has-background-white has-shadow pa-lg mb-xxs">
        <p><label class="label">Expense Lines</label></p>
        <div class="table-container">
          <table class="table is-fullwidth is-paddingless is-vcentered is-size-7" style="min-width: 800px;">
            <thead>
              <tr>
                <th style="width:15%">Category</th>
                <th>Description</th>
                <th style="width:15%">Amount</th>
                <th style="width:10%" class="has-text-centered">Is Billable?</th>
                <th style="width:15%">Billable Amount</th>
                <th style="width:5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in expense.lines" :key="index">
                <td>
                  <b-field>
                    <b-select
                      v-model="line.expense_category_id"
                      :readonly="readonly"
                      :disabled="readonly">
                      <option v-for="category in expenseCategories" :key="category.id" :value="category.id">{{ category.name }}</option>
                    </b-select>
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                      v-model="line.description"
                      :readonly="readonly"
                      :disabled="readonly" />
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                      v-model="line.cost"
                      type="number"
                      :readonly="readonly"
                      :disabled="readonly"
                      :step="0.01"
                    />
                  </b-field>
                </td>
                <td class="has-text-centered">
                  <b-field>
                    <b-checkbox
                      v-model="line.is_billable"
                      :true-value="1"
                      :false-value="0"
                      :readonly="readonly"
                      :disabled="readonly"
                    ></b-checkbox>
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                      v-if="line.is_billable"
                      v-model="line.billable_subtotal"
                      type="number"
                      :step="0.01"
                      expanded
                      :readonly="readonly"
                      :disabled="readonly"
                    />
                    <b-input v-else :value="null" :readonly="true" :disabled="true" />
                  </b-field>
                </td>
                <!-- <td>
                  <b-field>
                    <b-input :value="margin" :readonly="true" :disabled="true" expanded />
                  </b-field>
                </td> -->
                <td class="has-text-centered is-size-7">
                  <a href="#" @click.prevent="removeLine"  v-if="!readonly">
                    <b-icon icon="delete-outline" />
                  </a>
                </td>
              </tr>
              <!-- <template v-if="expense && 'lines' in expense">
                <ExpenseLine
                  v-for="(line, index) in expense.lines"
                  :key="line.id"
                  :line="line"
                  :readonly="readonly"
                  :index="index"
                  @remove:line="removeLine"
                />
              </template> -->
              <tr>
                <td>
                  <a v-if="!readonly" href="#" class="has-text-weight-bold" @click.prevent="addLine" >Add Additional Line</a>
                </td>
                <td></td>
                <td><b-input :value="(totals.cost) ?? null" :disabled="true" :readonly="true" /></td>
                <td></td>
                <td><b-input :value="(totals.billable) ?? null" :disabled="true" :readonly="true"/></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Section 3: Pre Approval Attachments (Sometimes) -->
      <div v-if="project.client.organization.preapproves_expenses" class="has-background-white has-shadow pa-lg mb-xxs">
        <div class="columns is-vcentered">
          <div class="column">
            <div class="mb-sm">
              <label class="label">Expense Approval</label>
              <p class="mb-md">
                {{ project.client.organization.name }} requires pre-approval of all expenses.
                Please upload at least one document that demonstrates you had client approval for this expense.
              </p>
            </div>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" @click="uploadPreapproval">Upload File</b-button>
          </div>
        </div>
        <!-- Pending Attachments -->
        <div v-if="approvalTempfiles.length">
          <p class="mb-md"><label class="label">Pending Uploads</label></p>
          <div v-for="(tempfile, index) in approvalTempfiles" :key="tempfile.id" class="has-background-light px-md rounded-lg mb-md">
            <div class="columns">
              <div class="column">{{ tempfile.original_name }}</div>
              <div class="column">
                <p class="has-text-right">
                  <a href="#" @click.prevent="removePreapprovalAttachment(index)">remove</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Section 4: Evidence Attachments -->
      <div class="has-background-white has-shadow pa-lg mb-xxs">
        <div class="columns is-vcentered">
          <div class="column">
            <div class="mb-sm">
              <label class="label">Receipts &amp; Attachments</label>
              <p class="mb-md">Attach receipts or any other evidence of completion. </p>
            </div>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" @click="uploadAttachment" :disabled="readonly">Upload File</b-button>
          </div>
        </div>
        <!-- Pending Attachments -->
        <div v-if="expense.attachments.length">
          <p class="mb-sm"><label class="label">Attachments</label></p>
          <div v-for="(attachment, index) in expense.attachments" :key="attachment.id" class="has-background-light pa-md rounded-lg mb-md">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <p>
                    <span class="has-text-primary">Attachment #{{ index + 1}} </span>
                    <span class="is-size-7 has-text-grey-light">{{ $utils.truncate(attachment.filename, { length: 64 }) }}</span>
                  </p>
                </div>
              </div>
              <div class="level-right">
                <DownloadLink
                  :attachment="attachment"
                  :route="'projects/' + project.id + '/expenses/' + expense.id"
                  :readonly="readonly"
                  @attachment:deleted="loadExpense(true)" />
              </div>
            </div>
          </div>
        </div>
        <!-- Pending Attachments -->
        <div v-if="attachmentTempfiles.length">
          <p class="mb-md"><label class="label">Pending Uploads</label></p>
          <div v-for="(tempfile, index) in attachmentTempfiles" :key="tempfile.id" class="has-background-light px-md rounded-lg mb-md">
            <div class="columns">
              <div class="column">{{ tempfile.original_name }}</div>
              <div class="column">
                <p class="has-text-right">
                  <a href="#" @click.prevent="removeEvidenceAttachment(index)">remove</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Submit -->
      <div class="has-background-white has-shadow px-lg py-md mb-xxs">
        <div class="columns">
          <div class="column is-one-quarter">
            <b-button
              native-type="submit"
              type="is-primary"
              size="is-medium"
              expanded
              :loading="saving"
              :disabled="readonly || saving || !ready"
            >Save Expense</b-button>
          </div>
        </div>
      </div>
    </form>
    <div v-else>
      <Loading message="Loading..." />
    </div>
    <!-- Modal: Details Modal -->
    <b-modal
      has-modal-card
      scr oll="keep"
      :active.sync="showDetailsModal"
      :can-cancel="['x', 'esc']"
      @close="attachmentType = null"
      ><ExpenseDetailsModal :expense="expense" />
    </b-modal>
    <!-- Modal: Add Attachment -->
    <b-modal
      has-modal-card
      scr oll="keep"
      :active.sync="showAddAttachmentModal"
      :can-cancel="['x', 'esc']"
      @close="attachmentType = null"
      ><UploadExpenseAttachmentModal :type="attachmentType" @tempfile:saved="attachFile" />
    </b-modal>
    <!-- Modal: Approve -->
    <b-modal
      v-if="expense"
      has-modal-card
      scroll="keep"
      :active.sync="showApproveExpenseModal"
      :can-cancel="['x', 'esc']"
      ><ApproveExpenseModal
        :project="project"
        :expenses="[expense]"
        :period="expense.billing_period"
        @project:saved="loadExpense(true)" />
    </b-modal>
    <!-- Modal: Unapprove -->
    <b-modal
      v-if="expense"
      has-modal-card
      scroll="keep"
      :active.sync="showUnapproveExpenseModal"
      :can-cancel="['x', 'esc']"
      ><UnapproveExpenseModal
        :project="project"
        :expenses="[expense]"
        :period="expense.billing_period"
        @project:saved="loadExpense(true)" />
    </b-modal>
    <!-- Modal: Archive -->
    <b-modal
      v-if="expense"
      has-modal-card
      scroll="keep"
      :active.sync="showArchiveExpenseModal"
      :can-cancel="['x', 'esc']"
      ><ArchiveExpenseModal :project="project" :expense="expense" @expense:saved="returnToExpenses" />
    </b-modal>
    <!-- Modal: Export -->
    <b-modal
      v-if="expense"
      has-modal-card
      scroll="keep"
      :active.sync="showExportExpenseModal"
      :can-cancel="['x', 'esc']"
      ><ExportExpenseModal :project="project" :expense="expense" @expense:saved="loadExpense(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Expense/Presenters/Status'
import UploadExpenseAttachmentModal from '@/components/Models/Expense/Modals/UploadAttachment'
import ApproveExpenseModal from '@/components/Models/Expense/Modals/Approve'
import UnapproveExpenseModal from '@/components/Models/Expense/Modals/Unapprove'
import ArchiveExpenseModal from '@/components/Models/Expense/Modals/Archive'
import ExportExpenseModal from '@/components/Models/Expense/Modals/Export'
import ExpenseDetailsModal from '@/components/Models/Expense/Modals/Details'
import DownloadLink from '@/components/Models/Attachment/DownloadLink'

export default {
  components: {
    ApproveExpenseModal,
    ArchiveExpenseModal,
    DownloadLink,
    ExpenseDetailsModal,
    ExportExpenseModal,
    StatusPresenter,
    UploadExpenseAttachmentModal,
    UnapproveExpenseModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return Boolean(this.loadingExpense || this.loadingExpenseCategories || this.loadingTerms)
    },
    readonly () {
      return Boolean(this.expense.approved_at !== null)
    },
    exportable () {
      return Boolean(this.expense.approved_at !== null && this.expense.exported_at === null)
    },
    method () {
      return (this.expense.id) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.$route.params.expense)
        ? this.api.route + '/projects/' + this.project.id + '/expenses/' + this.$route.params.expense
        : this.api.route + '/projects/' + this.project.id + '/expenses/'
    },
    requiresApproval () {
      return Boolean(this.project.client.organization.preapproves_expenses)
    },
    ready () {
      if (
        !this.expense.vendor ||
        !this.expense.payment_method_used ||
        !this.expense.due_date ||
        !this.expense.expense_date ||
        !this.expense.sales_term_id
      ) {
        return false
      }
      let ready = true
      this.expense.lines.forEach(line => {
        if (!line.expense_category_id) ready = false
        if (!line.description || !line.cost) ready = false
        if (line.is_billable === null) ready = false
        if (line.is_billable && !line.billable_subtotal) ready = false
      })
      return ready
    },
    json () {
      if (!this.ready) return {}
      const json = {
        vendor: this.expense.vendor,
        description: this.expense.description,
        sales_term_id: this.expense.sales_term_id,
        payment_method_used: this.expense.payment_method_used,
        due_date: this.$dates(this.expense.due_date).format('YYYY-MM-DD'),
        expense_date: this.$dates(this.expense.expense_date).format('YYYY-MM-DD'),
        partner_role_id: this.expense.partner_role_id,
        lines: [],
        tempfiles: [
          ...this.approvalTempfiles,
          ...this.attachmentTempfiles
        ]
      }
      this.expense.lines.forEach(line => {
        const dto = {
          id: line.id,
          expense_category_id: line.expense_category_id,
          description: line.description,
          cost: line.cost,
          is_billable: line.is_billable,
          billable_subtotal: (line.is_billable) ? line.billable_subtotal : 0,
          billable_adjustment: 0
        }
        json.lines.push(dto)
      })
      return json
    },
    partners () {
      const partners = {}
      this.project.positions.forEach(position => {
        position.seats.forEach(seat => {
          if (seat.contributor) {
            partners[seat.contributor.resource_id] = seat.contributor.resource.name
          }
        })
      })
      return partners
    },
    contributors () {
      const contributors = {}
      this.project.positions.forEach(position => {
        position.seats.forEach(seat => {
          if (seat.contributor) {
            contributors[seat.partner_role_id] = seat.contributor.user.full_name
          }
        })
      })
      return contributors
    },
    totals () {
      let cost = 0
      let billable = 0
      // let margin = 0
      if (this.expense) {
        this.expense.lines.forEach(line => {
          if (line.cost) cost += Number(line.cost)
          if (line.is_billable && line.billable_subtotal) billable += Number(line.billable_subtotal)
        })
        // if (cost && billable) {
        //   margin = (billable - cost) / cost
        // }
      }
      return {
        cost: this.$numbers(cost).formatCurrency({ mantissa: 2, thousandSeparated: true }),
        billable: this.$numbers(billable).formatCurrency({ mantissa: 2, thousandSeparated: true })
        // margin: this.$numbers(margin).format({ output: 'percent', mantissa: 1, thousandSeparated: true })
      }
    },
    expenseDate () {
      return this.expense.expense_date
    },
    terms () {
      return this.expense.sales_term_id
    }
  },
  data () {
    return {
      loadingSalesTerms: true,
      loadingExpenseCategories: true,
      loadingExpense: true,
      expenseCategories: [],
      salesTerms: [],
      expense: {
        id: null,
        vendor: null,
        description: null,
        sales_term_id: null,
        due_date: null,
        expense_date: null,
        partner_role_id: null,
        status: 'pending',
        approved_at: null,
        exported_at: null,
        lines: [],
        attachments: []
      },
      attachmentType: null,
      approvalTempfiles: [],
      attachmentTempfiles: [],
      saving: false,
      errors: {},
      showAddAttachmentModal: false,
      showApproveExpenseModal: false,
      showUnapproveExpenseModal: false,
      showArchiveExpenseModal: false,
      showExportExpenseModal: false,
      showDetailsModal: false
    }
  },
  methods: {
    loadSalesTerms () {
      this.loadingSalesTerms = true
      const endpoint = this.api.route + '/sales-terms'
      this.$http.get(endpoint).then(response => {
        this.salesTerms = response.data.data
        this.loadingSalesTerms = false
      })
    },
    loadExpenseCategories () {
      this.loadingExpenseCategories = true
      const endpoint = this.api.route + '/expense-categories'
      this.$http.get(endpoint).then(response => {
        this.expenseCategories = response.data.data
        this.loadingExpenseCategories = false
      })
    },
    loadExpense (quiet = false) {
      if (!quiet) this.loadingExpense = true
      this.$http.get(this.endpoint).then(response => {
        this.expense = response.data
        if (this.expense.lines.length) {
          this.expense.lines.forEach(line => {
            line.cost = Number(line.cost).toFixed(2)
            line.billable_subtotal = Number(line.billable_subtotal).toFixed(2)
          })
        } else {
          this.addLine()
        }
        this.loadingExpense = false
      })
    },
    save () {
      this.saving = true
      this.errors = {}
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.approvalTempfiles = []
        this.attachmentTempfiles = []
        this.$buefy.toast.open({ type: 'is-success', message: 'Expense saved!' })
        if (this.method === 'post') {
          this.$router.push({ name: 'network.project.expense', params: { expense: response.data.id } })
        }
        this.loadExpense(true)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    uploadPreapproval () {
      this.attachmentType = 'expense:preapproval'
      this.showAddAttachmentModal = true
    },
    uploadAttachment () {
      this.attachmentType = 'expense:attachment'
      this.showAddAttachmentModal = true
    },
    attachFile (tempfile) {
      if (tempfile.tag === 'expense:preapproval') {
        this.approvalTempfiles.push(tempfile)
      } else {
        this.attachmentTempfiles.push(tempfile)
      }
    },
    removePreapprovalAttachment (index) {
      this.approvalTempfiles.splice(index, 1)
    },
    removeEvidenceAttachment (index) {
      this.attachmentTempfiles.splice(index, 1)
    },
    returnToExpenses () {

    },
    addLine () {
      this.expense.lines.push({
        id: null,
        expense_category_id: (this.expenseCategories.length) ? this.expenseCategories[0] : null,
        description: null,
        is_billable: false,
        cost: null,
        billable_subtotal: null
      })
    },
    removeLine (index) {
      this.$set(this.expense, 'lines', this.expense.lines.toSpliced(index, 1))
      if (!this.expense.lines.length) {
        this.addLine()
      }
    }
  },
  mounted () {
    this.loadSalesTerms()
    this.loadExpenseCategories()
    if (typeof this.$route.params.expense !== 'undefined') {
      this.expense.id = this.$route.params.expense
      this.loadExpense()
    } else {
      this.addLine()
      this.loadingExpense = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    expenseDate (value) {
      if (!this.readonly && this.expense.sales_term_id) {
        const term = this.salesTerms.find((obj) => {
          return obj.id === this.expense.sales_term_id
        })
        if (term) {
          this.expense.due_date = this.$dates(value).add(term.days, 'day').format('YYYY-MM-DD')
        }
      }
    },
    terms (value) {
      if (!this.readonly && this.expense.expense_date) {
        const term = this.salesTerms.find((obj) => {
          return obj.id === value
        })
        if (term) {
          this.expense.due_date = this.$dates(this.expense.expense_date).add(term.days, 'day').format('YYYY-MM-DD')
        }
      }
    }
  }
}
</script>
